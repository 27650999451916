<template lang="pug">
div.view_container
  Header
  AddVideo(
    v-if="!isSafari && !isIOS"
    :showPrompt="true"
    :project="project"
    v-on:done="goNext"
    ref="add_video"
  )
  AddVideoWithFileUpload(
    v-if="isIOS"
    :showPrompt="true"
    :project="project"
    v-on:done="goNext"
  )
</template>

<script>
/* global gtag */
import config from '../appConfig'
import AddVideo from '../components/AddVideo'
import AddVideoWithFileUpload from '../components/AddVideoWithFileUpload'
import Header from '../components/Header'

import { GET_PROJECT_QUERY } from '../graphql/queries'

export default {
  name: 'AddVideoContainer',
  props: {
    id: {
      type: Number,
      default: null,
    },
    next: {
      type: String,
      default: null,
    }
  },
  components: {
    AddVideo,
    AddVideoWithFileUpload,
    Header,
  },
  computed: {
    nextRoute () {
      if (this.next) {
        return this.next
      } else if (this.$route.params.next) {
        return this.$route.params.next
      }
      return null
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    currentProject () {
      return this.$store.state.currentProject
    },
    currentProjectId () {
      if (this.id) return this.id
      return this.currentProject?.id
    }
  },
  watch: {
    project: function () {
      if (!this.project) return
      this.$store.commit('setProject', this.project)
    },
  },
  data () {
    return {
      isIOS: false,
      isSafari: false,
    }
  },
  apollo: {
    project () {
      return {
        query: GET_PROJECT_QUERY,
        variables () {
          return {
           id: this.currentProjectId
          }
        },
        skip () {
          return !this.currentProjectId || !this.isSignedIn
        },
        fetchPolicy: 'cache-and-network',
      }
    },
  },
  methods: {
    goNext: function() {
      if (this.nextRoute) {
        this.$router.push(this.nextRoute)
      } else {
        this.$router.push({name:"Done"})
      }
    },
    cleanUpVideo: function () {
      if (this.$refs.add_video) this.$refs.add_video.allStop()
    }
  },
  beforeMount: function () {
    this.isSafari = config.isSafari
    this.isIOS = config.isIOS
  },
  beforeRouteLeave(to, from, next) {
    this.cleanUpVideo()
    next()
  },
  beforeUnmount: function() {
    this.cleanUpVideo()
  },
  mounted: function() {
    // window.vue = this
    if (!this.isSignedIn) {
      this.$toast.error("Please sign in first. Click here to close this and we'll take you to the sign in page.",{
        timeout: null,
        onClose: () => this.$router.push({
          name:"LoginWithNext",
          params: {
            next: "/add_video" + (this.currentProjectId ? "/p/"+this.currentProjectId : "/")
          }
        })
      })
    } else if (this.isSafari && !this.isIOS) {
      this.$toast.warning(
        "We currently do not support Mac Safari, Please use chrome:  https://www.google.com/chrome",
        {
          timeout: false,
          onClose: () => window.location.href = "https://www.google.com/chrome"
      })
    }
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path':  '/add_video'})
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {

}

body {
  background-color: black;
}
</style>
